.ezc-app {
	//
	.page-receipt {
		//
		.receipt {
			//
			&__button-container {
				//
				.app-button--outline {
					display: none;
				}
			}
		}
	}
}

.page-receipt {
	width: 100%;

	.receipt {
		width: 100%;
		max-width: 1024px;
		margin: auto;
		padding: 50px 0;

		@include max-width($scr-sm) {
			padding: 15px;
		}

		&__icon {
			width: 240px;
			margin: 40px auto 0;
		}

		&__status {
			margin: 15px 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__body {
			background-color: $c-white;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__container {
			max-width: 960px;
			margin: 20px 40px;
			border-radius: 8px;
			background-color: rgba(192, 192, 192, 0.1);
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
		}

		&__title {
			margin: 0;
			padding: 15px;
			color: $c-white;
			font-size: $text-size-l;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			background-color: $c-turquoise;
			font-weight: $text-weight-regular;
		}

		&__button-container {
			padding: 20px;
			display: flex;
			justify-content: flex-end;

			@include max-width($scr-sm) {
				flex-direction: column;
				align-items: center;
			}

			.app-button {
				//
				&__label {
					font-size: $text-size-xs;
				}
			}
		}

		.receipt-form {
			//
			&__container {
				width: 100%;
				margin: auto;
				padding: 0 20px;
			}

			&__header {
				margin: 10px 0;
				gap: 0 20px;
				display: flex;
				flex-wrap: wrap;
			}

			&__body {
				margin: 10px 0;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;

				@include max-width($scr-sm) {
					gap: 0;
				}
			}

			&__wrapper {
				width: 100%;

				@include min-width($scr-sm) {
					width: calc(50% - 20px);
				}
			}

			&__payable-amount {
				width: 50%;
				padding: 0 20px 0 0;
				margin-left: auto;
				display: flex;
				flex-direction: column;

				@include max-width($scr-sm) {
					width: 100%;
				}
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}

			&__description {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;

				@include max-width($scr-sm) {
					text-align: center;
				}
			}

			&__footer {
				padding: 20px 0;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		.header-quotation-list {
			width: 100%;
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			flex-direction: column;

			@include min-width($scr-sm) {
				width: calc(50% - 20px);
			}

			&__item {
				margin: 10px 0;
				gap: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__description {
				margin: 0;
				color: $c-black;
				text-align: left;
				word-wrap: break-word;
				font-size: $text-size-s;
				font-weight: $text-weight-bold;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}
		}

		.quotation-list {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			flex-direction: column;

			&__item {
				margin: 10px 0;
				gap: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__text {
				margin: 0;
				color: $c-black;
				text-align: left;
				word-wrap: break-word;
				font-size: $text-size-s;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}

			&__description {
				margin: 0;
				color: $c-black;
				text-align: left;
				word-wrap: break-word;
				font-size: $text-size-s;
				font-weight: $text-weight-bold;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}
		}

		.step {
			width: 100%;

			&__body {
				padding: 0 0 20px;
			}

			&__wrapper {
				padding: 20px 40px;
				gap: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			&__box {
				width: 100%;
				display: flex;
				flex-direction: column;

				@include min-width($scr-sm) {
					width: calc(25% - 20px);
				}
			}

			&__content {
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					max-width: 115px;
					max-height: 111px;
				}
			}

			&__title {
				color: $c-primary;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__text {
				margin: 0;
				text-align: center;
				font-size: $text-size-xs;
				font-weight: $text-weight-regular;
			}

			&__container {
				padding: 20px 40px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
			}

			&__sub-title {
				color: $c-black;
				font-size: $text-size-s;
				font-weight: $text-weight-bold;
			}

			&__sub-text {
				margin: 0;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
			}

			&__button-container {
				margin: 0;
				gap: 24px;
				display: flex;

				@include max-width($scr-sm) {
					align-items: center;
					flex-direction: column;
				}
			}

			&__button {
				width: 200px;
				padding: 11px 0 0 0;
				border-radius: 8px;
				border: 1px solid $c-black;
				background-color: $c-black;
			}
		}

		.step-list {
			margin: 0 40px;
			padding: 0;

			&__item {
				margin: 0;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
			}
		}
	}
}
