.app-card-accordion {
	//
	.card-accordion {
		border-radius: 8px !important;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		&__title {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		.accordion-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			&__item {
				margin: 10px 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			&__text {
				margin: 0;
				text-align: left;
				color: $c-dark-grey;
				font-size: $text-size-s;
				word-wrap: break-word;
				word-break: break-word;
				flex: 1;

				&--value {
					color: $c-black;
					text-align: right;
				}
			}
		}

		.MuiAccordionDetails-root {
			padding: 0 20px 20px 20px;
		}
	}
}
