.app-status {
	//
	.status {
		display: inline-block;
		padding: 5px 10px;
		border-radius: 100px;
		background-color: transparent;

		&__text {
			margin: 0;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			word-break: keep-all;
		}

		&--blue {
			background-color: rgba(2, 69, 169, 0.1);

			.status__text {
				color: rgba(2, 69, 169, 1);
			}
		}

		&--green {
			background-color: rgba(0, 207, 156, 0.1);

			.status__text {
				color: rgba(0, 207, 156, 1);
			}
		}

		&--red {
			background-color: rgba(246, 68, 68, 0.1);

			.status__text {
				color: rgba(246, 68, 68, 1);
			}
		}
	}
}
