.app-id-scan {
    margin-top: 5px; 
    width: 400px; 
    height: 280px; 
    max-width: 100%;
    max-height: 100%;
    background-color: rgb(238, 238, 238); 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    border: 2px dashed; 
    border-color: rgb(175, 175, 175); 
    position: relative;
    border-radius: 5px;

    &__button {
        position: absolute;
        inset: 0; 
        opacity: 0; 
        cursor: pointer;
        z-index: 20;
    }

    &__frontImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    &__placeholder {
        z-index: 10;
        color: $c-dark-grey;
        font-size: $text-size-s;
        font-weight: $text-weight-semibold;
    }
}