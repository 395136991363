.page-app-store {
	width: 100%;
	height: 100%;

	.app-store {
		width: 100%;
		height: 100%;

		.main {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			align-items: stretch;

			@include max-width($scr-sm) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			&__content {
				width: 60%;

				@include max-width($scr-sm) {
					width: 100%;
				}

				&--form {
					max-width: 650px;
					padding: 20px;
					display: flex;
					align-items: center;

					@include min-width($scr-sm) {
						padding: 20px 20px 20px calc(5.5vw + 20px);
					}

					@include max-width($scr-sm) {
						justify-content: center;
					}
				}

				&--background {
					width: 40%;
					min-width: 400px;
					background-size: cover;
					background-repeat: no-repeat;
					background-image: url("../../../assets/images/pages/page-app-store/background.webp");
					display: flex;
					align-items: center;
					justify-content: flex-end;

					@include max-width($scr-sm) {
						display: none;
					}
				}
			}

			&__form {
				max-width: 400px;

				@include max-width($scr-sm) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}

			&__logo {
				width: 100%;
				max-width: 450px;
				margin-right: -5vw;

				@include max-width($scr-md) {
					max-width: 350px;
				}

				img {
					width: 100%;
				}
			}

			&__title {
				margin: 0;
				color: $c-teal;
				font-size: $text-size-xl;
				font-weight: $text-weight-semibold;

				@include max-width($scr-sm) {
					text-align: center;
				}
			}

			&__description {
				margin: 0;
				padding: 10px 0 0 0;
				color: $c-dark-grey;
				font-size: $text-size-body;

				@include max-width($scr-sm) {
					text-align: center;
				}
			}

			&__button-container {
				margin: 40px 0 0;
				gap: 24px;
				display: flex;
				flex-direction: column;
			}

			&__button {
				width: 224px;
				padding: 11px 0 0 0;
				border-radius: 60px;
				border: 1px solid $c-black;
				background-color: $c-black;
			}
		}
	}
}
