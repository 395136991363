.app-select-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	display: flex;

	&--disabled {
		background-color: #f8f8f8;
	}

	&__label {
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__icon {
		position: relative;
		width: 18px;
		height: 18px;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			border-radius: 0;
		}

		.MuiInputBase-input {
			padding: 10px 0;
		}

		.MuiSelect-select {
			color: $c-black;
			font-weight: $text-weight-normal;
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0 !important;
			border-bottom: 1px solid $c-disabled !important;
		}

		.MuiFormHelperText-root {
			margin-left: 0;
			color: $c-red;
			font-weight: $text-weight-normal;
			font-size: $text-size-xs;
		}
	}

	&--placeholder {
		.MuiFormControl-root {
			.MuiSelect-select {
				color: #e5e5e5;
			}
		}
	}
}

.app-select-input-modal {
	&__hidden {
		display: none !important;
	}

	.MuiPaper-root {
		max-height: 300px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}

			.MuiButtonBase-root {
				white-space: normal;
			}
		}
	}
}
