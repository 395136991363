.app-page {
	height: 100%;
}

.app-advance-search-form {
	//
	.advance-form {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		&__inputs {
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-calendar-input,
			.app-select-input,
			.app-multi-select-input {
				width: calc(50% - 7.5px);

				@include max-width($scr-sm) {
					width: 100%;
				}
			}
		}

		&__button-container {
			padding: 20px 0 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				width: auto;
				margin: 0 10px;
				padding: 10px 20px;

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-teal;
					}
				}
			}
		}

		&__remove {
			border: 0;
			margin: 0 -5px 0 5px;
			background-color: transparent;
			cursor: pointer;
			pointer-events: auto;
			flex-shrink: 0;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.app-table-menu {
	.MuiMenu-paper {
		border-radius: 8px;
		box-shadow: 0px 4px 12px 0px rgba(224, 229, 234, 0.6);

		.MuiButtonBase-root {
			padding: 10px 45px 10px 15px;
			color: $c-primary;
			font-size: $text-size-s;
		}

		.app-table-menu__remove {
			color: $c-red;
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin: 0 8px;
	}

	.app-checkbox {
		margin: 5px 0;

		&__label {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}
	}

	&__item {
		padding: 0 10px;
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__title {
		margin: 0;
		padding: 10px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-normal;
	}

	&__text {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
	}
}
