.app-button-input {
	width: 100%;

	&__label {
		margin: 0 0 5px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
	}

	&__container {
		height: 35px;
		width: 65%;
		display: flex;
	}

	&__button {
		position: relative;
		padding: 8px 10px;
		border-radius: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		background-color: transparent;
		border: 1px solid $c-light-grey;
		border-right: 0;
		border-left: 0;
		cursor: pointer;
		flex: 1;

		&:not(:first-child) {
			&::before {
				position: absolute;
				display: block;
				content: "";
				left: 0;
				top: 0;
				bottom: 0;
				width: 1px;
				background-color: $c-light-grey;
			}
		}

		&:first-child {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-left: 1px solid $c-light-grey;
		}

		&:last-child {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-right: 1px solid $c-light-grey;
		}

		&--active {
			color: $c-teal;
			border-top: 1px solid $c-teal;
			border-bottom: 1px solid $c-teal;
			font-weight: $text-weight-semibold;
			background-color: rgba(1, 136, 151, 0.1);

			&:first-child {
				border-left: 1px solid $c-teal;
			}

			&:last-child {
				border-right: 1px solid $c-teal;
			}

			+ .app-button-input__button {
				/* Target next sibling divider */
				&::before {
					background-color: $c-teal;
				}
			}

			&::before {
				background-color: $c-teal !important;
			}
		}
	}

	&__divider {
		width: 1px;
		background-color: $c-light-grey;

		&--active {
			background-color: $c-teal;
		}
	}

	&__error {
		margin: 8px 0 0;
		color: $c-red;
		font-size: $text-size-xs;
		font-weight: $text-weight-normal;
	}
}
