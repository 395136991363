.app-table-multi-select-input {
	position: relative;
	min-width: 150px;
	display: flex;
	align-items: center;

	&--disabled {
		background-color: #f8f8f8;
	}

	&__label {
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__icon {
		position: relative;
		width: 15px;
		height: 15px;
		flex-shrink: 0;
	}

	&__totals {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 35px;
		margin: auto 0 auto auto;
		width: 25px;
		height: 25px;
		color: $c-primary;
		font-size: $text-size-xs;
		font-weight: $text-weight-semibold;
		border-radius: 100px;
		background-color: rgba(1, 136, 151, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			padding: 14px;
			border-radius: 0;

			.app-icon {
				width: 12px;
				height: 12px;
			}
		}

		.MuiInputBase-input {
			padding: 0;
		}

		.MuiSelect-select {
			height: 0;
			padding-right: 40px !important;
			color: $c-primary;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}

		.MuiSelect-icon {
			right: -2.5px;
			top: calc(50% - 5.5px);
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 10px;
			border: 1px solid rgba(1, 136, 151, 0.3) !important;
		}

		.MuiFormHelperText-root {
			margin-left: 0;
			color: $c-red;
			font-weight: $text-weight-normal;
			font-size: $text-size-xs;
		}
	}
}

.app-table-multi-select-input-modal {
	.app-checkbox {
		&__box {
			border: 1px solid $c-primary;
		}
	}

	.MuiPaper-root {
		max-height: 300px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		.MuiButtonBase-root {
			padding-top: 0;
			padding-bottom: 0;
			min-height: 40px;
			background-color: transparent !important;
		}

		.MuiListItemText-root {
			span {
				font-size: $text-size-s;
			}
		}

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 18px;
					height: 18px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}
		}
	}
}
