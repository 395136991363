.counter-button {

  margin-top: 10px;
  margin-bottom: 10px;

    .counter-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; /* Adds space between content and error container */
    }
  
    .info {
      flex: 1;
  
      .label {
        color: $c-dark-grey;
		  font-size: $text-size-s;
		  font-weight: $text-weight-semibold;
      }
  
      .description {
        font-size: $text-size-s;
        color: #818285; // Tailwind's gray-500
      }
    }
  
    .controls {
      display: flex;
      align-items: center;
  
      button {
        width: 2rem;
        height: 2rem;
        border: 1px solid #d1d5db; // Tailwind's gray-300
        border-radius: 0.25rem;
        text-align: center;
        background-color: #f3f4f6; // Tailwind's gray-100
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
  
        &:hover {
          background-color: #e5e7eb; // Tailwind's gray-200
        }
      }
  
      .count {
        width: 2rem;
        text-align: center;
        color: $c-black;
		    font-weight: $text-weight-normal;
      }
    }

    .error_container {
      margin-top: 10px;

      &__desc {
        width: 100%;
			  margin: 3px 0 0;
			  color: $c-red;
			  font-weight: $text-weight-normal;
			  font-size: $text-size-xs;
			  display: flex;
			  align-items: center;
			  justify-content: space-between;
      }
    }

    
  }