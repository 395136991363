.app-expired-modal {
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@include max-width($scr-sm) {
		padding: 10px;
	}

	.expired-modal {
		padding: 60px 40px 40px;
		width: 100%;
		max-width: 588px;
		border-radius: 8px;
		background-color: $c-white;
		position: relative;

		@include max-width($scr-sm) {
			padding: 20px;
		}

		&__container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.app-button {
				margin-top: 30px;
			}
		}

		&__title {
			margin: 0;
			text-align: center;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 8px 0 0 0;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}
	}
}
