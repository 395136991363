.app-layout {
	width: 100%;
	height: 100%;
	min-width: 320px;
	background-color: $c-background;

	.main {
		width: 100%;
		height: 100%;

		&__container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__page {
			flex: 1;
			background-color: $c-background;
		}

		&__copyright {
			padding: 10px;
			margin: auto 0 0;
			text-align: center;
			color: $c-light-grey;
			font-size: $text-size-xs;
			background-color: $c-background;
		}
	}
}
