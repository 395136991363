.page-pa-descript {
	height: 100%;

	.enquiry {
		height: 100%;

		&__container {
			width: 100%;
			height: 100%;
			max-width: 1024px;
			margin: 0 auto;
			padding: 40px 20px;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}

		&__form {
			height: 100%;
		}

		&__box {
			height: 100%;
			border-radius: 8px;
			background-color: $c-white;
		}

		&__wrapper {
			
			margin: auto;
			padding: 40px;

			@include max-width($scr-xs) {
				padding: 25px 15px;
			}
		}

		&__title {
			margin-bottom: 20px;
			margin-top: 50px;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;

			@include max-width($scr-xs) {
				text-align: center;
			}
		}

		&__big-title {
			margin-bottom: 20px;
			margin-top: 50px;
			color: $c-primary;
			font-size: $text-size-xxl;
			font-weight: $text-weight-semibold;
			text-decoration: underline;

			@include max-width($scr-xs) {
				text-align: center;
			}
		}

		&__description {
			text-align: justify;
			margin: 10px;
			color: $c-dark-grey;
			font-size: $text-size-body;

			@include max-width($scr-xs) {
				text-align: center;
			}
		}

		&__description-sub {
			text-align: justify;
			margin-bottom: 2rem;
			padding-left: 15px;
			color: $c-dark-grey;
			font-size: $text-size-body;

			@include max-width($scr-xs) {
				text-align: center;
				padding-left: 0px;
			}
		}

		&__description-sub-sub {
			text-align: justify;
			margin-bottom: 2rem;
			padding-left: 30px;
			color: $c-dark-grey;
			font-size: $text-size-body;

			@include max-width($scr-xs) {
				text-align: center;
				padding-left: 0px;
			}
		}

		&__description-title {
			text-align: justify;
			margin: 10px;
			margin-top: 30px;
			color: $c-dark-grey;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;

			@include max-width($scr-xs) {
				text-align: center;
			}
		}

		&__description-important {
			text-align: center;
			margin-top: 50px;
			margin-bottom: 10px;
			color: $c-light-grey;
			font-size: $text-size-xs;

			@include max-width($scr-xs) {
				text-align: justify;
			}
		}
		

		&__button-container {
			width: 100%;
			padding: 20px 0;
			margin-top: 50px;
			display: flex; /* Ensure flexbox is applied */
      		flex-direction: column; /* Arrange children in a column */
      		justify-content: center; /* Center vertically */
      		align-items: center; /* Center horizontally */
		}

		&__tnc-pdf {
			color: $c-blue;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			text-decoration: none;
		}

		&__tnc-bold {
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			text-decoration: none;
		}

		&__tnc-required {
			color: $c-red;
		}

		&__checkbox {
			margin-top: 50px;
		}

		&__divider {
			margin-top:60px;
			border: 1px dashed $c-disabled;
		}

		&__table table{
			width: 100%;
			border-collapse: collapse;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		&__td-header{
			border: 1px solid #ddd;
			padding: 8px;
			background-color: #f2f2f2;
			text-align: center;
			font-weight: $text-weight-semibold;
			font-size: $text-size-body;
		}

		&__td-row{
			border: 1px solid #ddd;
			padding: 8px;
			text-align: center;
			font-size: $text-size-body;
		}
	}
}
