.app-loading {
	width: 100%;
	height: 100%;

	.loading {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__body {
			max-width: 500px;
			padding: 15px;
			margin: auto;
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&__processing-icon {
			width: 144px;
			height: 180px;
		}

		&__title {
			margin: 15px 0 0 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__sub-title {
			margin: 0;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}
	}
}
