.app-button-image {
	max-width: 250px;
	width: 100%;
	border: 1px solid $c-teal;
	padding: 30px 20px;
	border-radius: 50px;
	background-color: transparent;
	cursor: pointer;
	display: flex;
    flex-direction: column;
	align-items: center;
	justify-content: center;

    &:hover{
        background-color: lightgrey;  /* Change background to blue */
        color: white;  /* Change text color to white */
    }

	&:disabled {
		border: 1px solid $c-disabled;
		background-color: $c-disabled;

		.app-button__label {
			color: #828a8f;
		}
	}

	&--outline {
		background-color: transparent;

		.app-button__label {
			color: $c-teal;
		}
	}

	&__label {
		margin: 0;
		color: $c-teal;
		font-weight: 700;
		font-size: $text-size-s;
	}

    &__image {
        width: 100%;
        height: 100%;
        max-width: 200px; /* Adjust size as needed */
        max-height: 200px; /* Adjust size as needed */
        margin-bottom: 10px;
    }
}
