.app-pop-out {
    &__label {
      color: $c-white;
    }
  }
  
  .popout {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  
    &__content {
      background-color: white;
      padding: 1.5rem;
      border-radius: 0.5rem;
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
      max-width: 700px; // Adjust width as needed
    }
  
    &__title {
      color: $c-primary;
      font-size: $text-size-l;
      font-weight: $text-weight-semibold;
      margin-bottom: 1rem;
      text-align: center;
    }
  
    &__descript {
      font-size: $text-size-m;
      font-weight: $text-weight-regular;
      margin-bottom: 3rem;
      text-align: center;
    }
  
    &__button {
      display: flex;
      justify-content: space-between; // Distribute buttons to the left and right
      margin-top: 1rem;
    }
  }