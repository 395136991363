.app-checkbox {
	//
	.checkbox {
		border: 0;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
		display: flex;

		&:disabled {
			//
			.checkbox {
				//
				&__box {
					border: 1px solid $c-dark-grey;
				}

				&__label {
					color: $c-dark-grey;
				}

				&__logo {
					filter: grayscale(1);
				}
			}
		}

		&__box {
			width: 20px;
			height: 20px;
			padding: 3px;
			border-radius: 4px;
			border: 1px solid $c-teal;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-icon {
				width: 100%;

				&__i {
					width: 100%;
				}
			}
		}

		&__label {
			margin: -3.5px 8px 0;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			display: inline-block;
			text-align: left;
		}

		&__logo {
			margin: 0 20px;
		}

		&__error {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
